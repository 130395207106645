import React, { useEffect, useState } from "react"
import { Col, Row, Typography, Space, Button, Popover } from "antd"
import moment from "moment"
import {
  // CommentOutlined,
  ShareAltOutlined,
  PlayCircleTwoTone
} from "@ant-design/icons"
import Container from "@components/Container/Container"
import { useNavigate, useParams } from "react-router-dom"
import Socials from "@components/Socials"
import { SideBar } from "@containers/Sidebar"
import { useSelector } from "react-redux"
const { Title } = Typography
import { Date } from "@/components/Date"
import { setPageHeader } from "@slices/headerSlice"
import { useDispatch } from "react-redux"
import axios from "axios"
import { MainLayout } from "@containers/Layouts/MainLayout"
// import { Comments } from "../Comments/Comments"
// import Likes from "@/components/Likes"
import { useSite } from "@hooks/useSite"
import ReactPlayer from "react-player"
import AudioPlayer from "react-h5-audio-player"
import "react-h5-audio-player/lib/styles.css"
import { useTranslation } from "react-i18next"
import "./single-post.sass"
import { SinglePostSkeleton } from "@components/SinglePostSkeleton"
import { SinglePostImage } from "@components/SinglePostImage"
import Report from "@/components/Report"
import { AdComponent } from "@/components/AdComponent"
// import { Link } from "react-router-dom"


const SinglePost = () => {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const [isMounted, setIsmounted] = useState(false)
  const { setPageTitle } = useSite()
  const [post, setPost] = useState(null)
  // const [commentsCount, setCommentsCount] = useState(0)
  const { post_title } = useParams()
  const navigate = useNavigate()
  const { t, i18n } = useTranslation()
  const lang = i18n.language
  const { posts } = useSelector((state) => state.homePosts)
  const isContentNotEmpty =
        post?.content.trim().replace(/<[^>]*>?/gm, "").length > 0
  const IncrementViews = () => {
    axios
      .post(`/post/record-view/${post.id}`)
      .then(() => {})
      .catch((error) => {
        axios.post("/error/", {
          message: `${error.message}`
        })
      })
  }

  const fetchPost = async (slug) => {
    setLoading(true)
    axios
      .get(`/post/fetchpostdata/${slug}`)
      .then((response) => {
        if (!response.data || response.data.length === 0) {
          navigate(`/${lang}/404`)
          return
        }
        setPost(response.data[0])
        setLoading(false)
      })
      .catch((error) => {
        setLoading(false)
        axios.post("/error/", {
          message: `${error.message}`
        })
      })
  }
  const getPost = async (slug) => {
    let exists = posts.filter((item) => item.slug === slug)[0]
    if (!exists) {
      exists = await fetchPost(slug)
      return
    }
    setPost(exists)
  }
  useEffect(() => {
    const page = document.getElementById("page")
    if (page) {
      page.scrollTo({ top: 0 })
    }
    setPost(null)
    getPost(post_title)
  }, [post_title])

  useEffect(() => {
    setIsmounted(false)
    if (post && post.id) {
      IncrementViews()
      dispatch(
        setPageHeader({
          title: post.title,
          description: post.intro,
          image: post.image
        })
      )
      setPageTitle(post.title)
      setIsmounted(true)
    }
    return () => {
      setIsmounted(false)
    }
  }, [post])

  return (
    <MainLayout key={post_title}>
      <AdComponent space_uuid="banner_single_post" />
      <>
        <Container>
          <Row>
            <Col md={16}>
              {loading && <SinglePostSkeleton />}
              {!loading && isMounted && post && (
                <>
                  <Title
                    level={1}
                    style={{ margin: 0 }}
                    className="single-post-title"
                  >
                    {post.title}
                  </Title>
                  <div className="single-post-info">
                    <span>
                      {t("text.issued_on")}:{" "}
                      <Date
                        time={post.createdAt}
                        fromNow={false}
                        dateTime={true}
                      />
                    </span>
                    <span>
                      {t("text.by")}: {post.author.firstname}
                    </span>
                    {moment(post.updatedAt).isAfter(moment(post.createdAt)) && (
                      <span>
                        {t("text.updated_on")}:{" "}
                        <Date
                          time={post.updatedAt}
                          fromNow={false}
                          dateTime={true}
                        />
                      </span>
                    )}
                  </div>
                  {post.video ? (
                    <div
                      style={{
                        marginBottom: 10,
                        width: "100%"
                      }}
                    >
                      <ReactPlayer
                        width={"100%"}
                        height={"auto"}
                        style={{ aspectRatio: "16/9", background: "#000" }}
                        controls={true}
                        url={post.video}
                      />
                    </div>
                  ) : (
                    <SinglePostImage image={post.image} />
                  )}

                  {isContentNotEmpty && (
                    <div
                      style={{ marginBottom: 60 }}
                      dangerouslySetInnerHTML={{
                        __html: post.content
                      }}
                    ></div>
                  )}
                  {post.audio ? (
                    <div
                      style={{
                        maxWidth: 600,
                        marginBottom: 10
                      }}
                    >
                      <AudioPlayer
                        header={t("messages.listen_audio")}
                        hasDefaultKeyBindings="false"
                        src={`${process?.env?.REACT_APP_BACKEND_URL}/files/${post.audio}`}
                        customIcons={{
                          play: <PlayCircleTwoTone color="primary" />
                        }}
                      />
                    </div>
                  ) : null}
                  {/* <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      padding: "10px 0px",
                      columnGap: 10
                    }}
                  >
                    {post.tags?.map((item, index) => (
                      <Link
                        key={index}
                        to={{ pathname: `/${lang}/tag/${item.slug}` }}
                        title={post.title}
                      >
                        <Tag
                          color="default"
                        >
                          {item.name.toUpperCase()}
                        </Tag>
                      </Link>
                    ))}
                  </div> */}

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      padding: "10px 0px"
                    }}
                  >
                    <Space>
                      {/* <Likes postId={post.id} /> */}

                      <span>{post.viewCount} view(s)</span>

                      {/* <Space>
                        <Button
                          shape="circle"
                          size="large"
                          icon={<CommentOutlined />}
                        />
                        <span>
                          {commentsCount} comment(s)
                        </span>
                      </Space> */}
                    </Space>
                    <Space>
                      <Report postId={post.id} />
                      <Popover
                        placement="top"
                        content={Socials}
                        title=""
                        trigger="click"
                      >
                        <Button size="small" icon={<ShareAltOutlined />} />
                      </Popover>
                    </Space>
                  </div>
                  {/* ============post comment disabled============  */}
                  {/* <Comments
                                        postId={post.id}
                                        setCommentsCount={setCommentsCount}
                                      /> */}
                </>
              )}
            </Col>
            <Col md={8}>
              <SideBar />
            </Col>
          </Row>
        </Container>
      </>
      <AdComponent space_uuid="banner_single_post" />
    </MainLayout>
  )
}

export { SinglePost }
