import React from "react"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import { Logo } from "@components/Logo"
import { Space, Button } from "antd"
import { SearchButton } from "@components/SearchButton"

import { MenuOutlined } from "@ant-design/icons"
import "./navbar.scss"
// import { LanguageSelector } from "@components/LanguageSelector"

import { useTranslation } from "react-i18next"
// import ProfilDropdown from "./ProfilDropdown"
// import LogOutButton from "./LogOutButton"
// import { useSelector } from "react-redux"

const Navbar = ({ handleMenu }) => {
  const { t, i18n } = useTranslation()
  const lang = i18n.language
  // const { isLogged } = useSelector((state) => state.auth)
  return (
    <>
      <div className="navbar">
        <div className="container">
          <div className="navbar-logo">
            <Link to={`/${lang}/`}>
              <Logo />
            </Link>
          </div>
          <div className="navbar-left">
            <ul className="navbar-menu">
              <li className="navbar-menu-item">
                <Link to={`/${lang}/`}>
                  <span>{t("menu.news")}</span>
                </Link>
              </li>
              <li className="navbar-menu-item">
                <Link
                  to={`/${lang}/${t("router.category")}/${t("router.musics")}`}
                  target={""}
                >
                  <span>{t("menu.music")}</span>
                </Link>
              </li>
              {/* <li className="navbar-menu-item">
                <Link
                  to={`/${lang}/${t("router.category")}/people`}
                  target={""}
                >
                  <span>{t("menu.people")}</span>
                </Link>
              </li> */}
              <li className="navbar-menu-item">
                <Link
                  to={`/${lang}/${t("router.category")}/cinema`}
                  target={""}
                >
                  <span>{t("menu.cinema")}</span>
                </Link>
              </li>
              <li className="navbar-menu-item">
                <Link
                  to={`/${lang}/${t("router.category")}/videos`}
                  target={""}
                >
                  <span>{t("menu.videos")}</span>
                </Link>
              </li>
              {/* <li className="navbar-menu-item">
                <Link
                  to={`/${lang}/${t("router.category")}/${t("router.artists")}`}
                  target={""}
                >
                  <span>{t("menu.artists")}</span>
                </Link>
              </li> */}
            </ul>
          </div>
          <div className="navbar-right">
            <Space wrap>
              <ul className="navbar-menu">
                <li className="navbar-menu-item on-desktop">
                  <SearchButton />
                </li>
                {/* <li className="navbar-menu-item on-desktop">
                  <LanguageSelector />
                </li> */}
                {/* <li className="navbar-menu-item on-desktop">
                  {isLogged ? <ProfilDropdown /> : <LogOutButton />}
                </li> */}

                <li className="navbar-menu-item on-mobile">
                  <Button
                    type="text"
                    shape="circle"
                    icon={<MenuOutlined />}
                    onClick={handleMenu}
                  />
                </li>
              </ul>
            </Space>
          </div>
        </div>
      </div>
    </>
  )
}

Navbar.propTypes = {
  handleMenu: PropTypes.func
}
Navbar.defaultProps = {
  handleMenu: null
}
export { Navbar }
