import React, { useEffect } from "react"
import {
  BrowserRouter,
  Navigate,
  Route,
  Routes,
  useParams
} from "react-router-dom"
import Category from "@containers/Category"
import Home from "@containers/Home"
import { SinglePost } from "@containers/SinglePost"
import { SearchPage } from "@containers/Search"
import PageNotFound from "@/components/ErrorPages/404"
import ErrorBoundary from "./containers/Errors/ErrorBoundary"
import { ErrorBoundaryFallback } from "./components/ErrorBoundaryFallback"
import { Login } from "./containers/Auth/Login"
import { ForgotPassword } from "./containers/Auth/ForgotPassword"
import Profile from "./containers/Profile/Profile"
import { ContributorStep1 } from "./containers/Auth/ContributorAuth/step-1"
import { ContributorStep2 } from "./containers/Auth/ContributorAuth/step-2"
import { ContributorStep3 } from "./containers/Auth/ContributorAuth/step-3"
import { ContributorStep4 } from "./containers/Auth/ContributorAuth/step-4"
import { ContributorSummary } from "./containers/Auth/ContributorAuth/summary"
import { Step1Component } from "./containers/Auth/Register/step-1"
import { Step2Component } from "./containers/Auth/Register/step-2"
import MentionLegal from "./containers/legals/mentionLegal"
import ConfidentialPolicy from "./containers/legals/confidentialPolicy"
import ConditionsOfService from "./containers/legals/conditionsOfService"
import TermsOfUse from "./containers/legals/termsOfUse"
import DataAndCookies from "./containers/legals/dataAndCookies"
import { useTranslation } from "react-i18next"
import Tags from "./containers/Tags"

const SUPPORTED_LANGUAGES = ["en", "fr"]
const LanguageRoutes = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<RedirectToDefaultLanguage />} />
        <Route path={`/:lang/*`} element={<Router />} />
      </Routes>
    </BrowserRouter>
  )
}
const RedirectToDefaultLanguage = () => {
  const browserLanguage =  "fr" // navigator.language.split("-")[0] ||
  const defaultLanguage = ["en", "fr"].includes(browserLanguage)
    ? browserLanguage
    : "fr"
  const { i18n } = useTranslation()
  const language = i18n.language || defaultLanguage
  console.log("RedirectToDefaultLanguage: ", language)
  return <Navigate to={`/${language}`} replace />
}

const Router = () => {
  const browserLanguage =  "fr"
  const defaultLanguage = ["en", "fr"].includes(browserLanguage)
    ? browserLanguage
    : "fr"
  const { lang } = useParams()
  const { i18n } = useTranslation()

  useEffect(() => {
    if (lang && lang !== i18n.language) {
      if (!SUPPORTED_LANGUAGES.includes(lang)) {
        <Navigate to={`/${defaultLanguage}/404`} replace />
      } else {
        i18n.changeLanguage(lang)
      }
    }
  }, [lang, i18n])
  if (!SUPPORTED_LANGUAGES.includes(lang)) {
    return <Navigate to={`/${defaultLanguage}/404`} replace />
  }
  return (
    <ErrorBoundary fallback={<ErrorBoundaryFallback />}>
      <Routes>
        <Route exact path="" element={<Home />} />
        <Route exact path="login" element={<Login />} />
        <Route exact path="profil" element={<Profile />} />
        <Route exact path="register" element={<Step1Component />} />
        <Route exact path="register/step-2" element={<Step2Component />} />
        <Route exact path="contributor" element={<ContributorStep1 />} />
        <Route exact path="contributor/step-2" element={<ContributorStep2 />} />
        <Route exact path="contributor/step-3" element={<ContributorStep3 />} />
        <Route exact path="contributor/step-4" element={<ContributorStep4 />} />
        <Route
          exact
          path="contributor/summary"
          element={<ContributorSummary />}
        />
        <Route exact path="forgot-password" element={<ForgotPassword />} />
        <Route exact path="recherche" element={<SearchPage />} />
        <Route exact path="mentions-legales" element={<MentionLegal />} />
        <Route
          exact
          path="conditions-of-services"
          element={<ConditionsOfService />}
        />
        <Route exact path="terms-of-use" element={<TermsOfUse />} />
        <Route exact path="data-and-cookies" element={<DataAndCookies />} />
        <Route exact path="policies" element={<ConfidentialPolicy />} />
        <Route path="/category/:category_name" element={<Category />} />
        <Route path="/tag/:tag_name" element={<Tags />} />
        <Route path="categorie/:category_name" element={<Category />} />
        <Route path=":category_name/:post_title" element={<SinglePost />} />
        <Route path="/404" element={<PageNotFound />} />
        <Route path="*" element={<Navigate to="/404" />} />
      </Routes>
    </ErrorBoundary>
  )
}

export { LanguageRoutes }
