import React, { useEffect, useState } from "react"
import { Col, Row, Typography } from "antd"
import PropTypes from "prop-types"
import PorstCard from "@/components/Post"
import Container from "./Container/Container"
import { SkeletonPostCard } from "./SkeletonPostCard"
import Lottie from "react-lottie"
import animationData from "../assets/lotties/animation_empty.json"
import { useTranslation } from "react-i18next"
import { get } from "@/axiosConfig"

export default function TagView({ tag_name }) {
  const [posts, setPosts] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  const { t } = useTranslation()
  const { Title } = Typography
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  }

  const fetchData = () => {
    setIsLoading(true)
    get(`/post/tags/${tag_name}`).then((res) => {
      if (res.data) {
        setPosts([...res.data])
        setIsLoading(false)
      }
    })
  }

  useEffect(() => {
    fetchData()
  }, [tag_name])
  return (
    <>
      <Container>
        <Title level={1} className="page-title" style={{ color: "red" }}>
          {tag_name}
        </Title>
        {isLoading ? (
          <Row gutter={[32, 32]}>
            <Col md={6} xs={24}>
              <SkeletonPostCard />
            </Col>
            <Col md={6} xs={24}>
              <SkeletonPostCard />
            </Col>
            <Col md={6} xs={24}>
              <SkeletonPostCard />
            </Col>
            <Col md={6} xs={24}>
              <SkeletonPostCard />
            </Col>
          </Row>
        ) : (
          <>
            <Row gutter={[32, 32]}>
              {posts.map((item, index) => (
                <Col key={index} md={8} xs={24}>
                  <PorstCard post={item} imageRatio="ratio_165" />
                </Col>
              ))}
            </Row>

            {posts.length === 0 && (
              <Row>
                <Col xs={24}>
                  <Lottie options={defaultOptions} width={200} height={200} />
                </Col>
                <Col xs={24}>
                  <p className="text-center" style={{ textAlign: "center" }}>
                    {t("empty_list")}
                  </p>
                </Col>
              </Row>
            )}
          </>
        )}
      </Container>
    </>
  )
}

TagView.propTypes = {
  tag_name: PropTypes.string,
  articles: PropTypes.arrayOf(PropTypes.instanceOf(Object))
}
TagView.defaultProps = {
  tag_name: null,
  articles: []
}
