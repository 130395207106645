import React, { useEffect } from "react"
import { connect } from "react-redux"
import { useParams } from "react-router-dom"
import { MainLayout } from "@containers/Layouts/MainLayout"
import { useSite } from "@hooks/useSite"
import { AdComponent } from "@/components/AdComponent"
import TagView from "@/components/TagView"

export const Tags = () => {
  const { tag_name } = useParams()
  const { setPageTitle } = useSite()

  useEffect(async () => {
    setPageTitle(tag_name.charAt(0).toUpperCase() + tag_name.slice(1))
  }, [tag_name])

  return (
    <MainLayout>
      <AdComponent key={tag_name} space_uuid="banner_tags" />
      <TagView tag_name={tag_name || ""} />
    </MainLayout>
  )
}

const mapStateToProps = (state) => state

export default connect(mapStateToProps)(Tags)
