/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react"
import { Button, Col, Row } from "antd"
import Container from "@components/Container/Container"
import { useSelector, useDispatch } from "react-redux"
import {
  setHomePosts,
  addHomePosts,
  setHomeLastPost
} from "@slices/homePostSlice"
import PostCard from "@/components/Post"
import { AdComponent } from "@/components/AdComponent"
import { SkeletonPostCard } from "@/components/SkeletonPostCard"
import LogoImage from "@images/logo.png"
import { setPageHeader } from "../slices/headerSlice"
import axios from "axios"
import { MainLayout } from "./Layouts/MainLayout"
import Title from "antd/lib/typography/Title"
import { useTranslation } from "react-i18next"
import { get, post } from "../axiosConfig"
import { i18n } from "@/i18n"
import useGetUser from "@/hooks/useGetUser"
import { getMessaging, getToken } from "firebase/messaging"
import app from "@/messaging"
import { useSite } from "@hooks/useSite"



export const Home = () => {
  const [hasMoreData, setHasMoreData] = useState(true)
  const [isLoaded, setIsLoaded] = useState(true)
  const dispatch = useDispatch()
  const { setPageTitle } = useSite()
  const { posts, lastPost } = useSelector((state) => state.homePosts)
  const [isLoading, setIsLoading] = useState(false)
  const [isLoadingMore, setIsLoadingMore] = useState(false)
  const { data } = useGetUser()
  const [postlimit] = useState(12)
  const login_token = localStorage.getItem("login_token")

  const { t } = useTranslation()

  const fetchMoreData = async () => {
    if (!lastPost?.id){
      return;
    }
    setIsLoadingMore(true)
    await get("/post/fetchpostdata", {
      last_post: lastPost.id,
      post_limit: postlimit
    })
      .then((res) => {
        if (res.data.length == 0) {
          setHasMoreData(false)
          setIsLoadingMore(false)
        } else {
          dispatch(addHomePosts(res.data))
          dispatch(setHomeLastPost(res.data[res.data.length - 1]))
          setIsLoadingMore(false)
        }
      })
      .catch(async (error) => {
        await post("/error/", {
          message: `${error.message}`
        })
      })
  }

  const fetchData = async () => {
    setIsLoading(true)
    await get("/post/fetchpostdata", {
      last_post: 0,
      post_limit: postlimit
    })
      .then((res) => {
        dispatch(setHomePosts(res.data))
        dispatch(setHomeLastPost(res.data[res.data.length - 1]))
        setIsLoading(false)
      })
      .catch(async (error) => {
        await post("/error/", {
          message: `${error.message}`
        })
      })
  }

  const init = () => {
    if (posts.length === 0) {
      fetchData()
      return
    }
    setIsLoading(true)
    setInterval(() => {
      setIsLoading(false)
    }, 100)
  }

  useEffect(() => {
    init()
    dispatch(
      setPageHeader({
        title: "Urbankiff",
        description: "Site web culture et société",
        image: LogoImage
      })
    )
    setPageTitle("Urbankiff")
    

    return () => setIsLoaded(false)
  }, [isLoaded])

  useEffect(() => {
    if (Object.keys(data).length > 0) {
      Notification.requestPermission().then((permission) => {
        if (permission === "granted") {
          // Initialize Firebase Cloud Messaging and get a reference to the service
          console.log("permissions granted")
          const messaging = getMessaging(app)
          getToken(messaging, {
            vapidKey:
              "BN3g1g7HhLtHnlZ2bZwg-2sJ8wBF9NIzoV4CFD5zdK_4ZQaK8XZydr_jJQlbGat-_hP-GWGCU2KCFFuneLRc6VE"
          })
            .then((currentToken) => {
              if (currentToken) {
                // Send the token to your server and update the UI if necessary
                // ...
                axios
                  .post(
                    "allow-notification",
                    { token: currentToken },
                    { headers: { Authorization: `Token ${login_token}` } }
                  )
                  .then((res) => {
                    if (res.status === 200) {
                      console.log("Notification allowed!")
                    }
                  })
              } else {
                // Show permission request UI
                console.log(
                  "No registration token available. Request permission to generate one."
                )
                // ...
              }
            })
            .catch((err) => {
              console.log("An error occurred while retrieving token. ", err)
              // ...
            })
        }
      })
    }
  }, [data])

  return (
    <MainLayout>
      <AdComponent space_uuid="campaign_main"/>
      {isLoading ? (
        <Container>
          <Row gutter={[32, 32]}>
            {Array(8)
              .fill()
              .map((_, index) => (
                <Col key={index} md={6} xs={24}>
                  <SkeletonPostCard />
                </Col>
              ))}
          </Row>
        </Container>
      ) : (
        <Container>
          <>
            
            <Row gutter={[32, 32]}>
              {posts.map((item, index) => (
                <Col key={index} md={8} xs={24}>
                  <PostCard post={item} hoverable={true} imageRatio="ratio_165"/>
                </Col>
              ))}
              {isLoadingMore && (
                <>
                  <Col md={6} xs={24}>
                    <SkeletonPostCard />
                  </Col>
                  <Col md={6} xs={24}>
                    <SkeletonPostCard />
                  </Col>
                  <Col md={6} xs={24}>
                    <SkeletonPostCard />
                  </Col>
                  <Col md={6} xs={24}>
                    <SkeletonPostCard />
                  </Col>
                </>
              )}
            </Row>
            {hasMoreData &&
              <div style={{ textAlign: "center", marginTop: 16 }}>
                <Button
                  danger
                  loading={isLoadingMore}
                  onClick={fetchMoreData}
                  shape="round"
                >
                  {t("buttons.load_more")}
                </Button>
              </div>
            }
            
          </>
        </Container>
      )}
    </MainLayout>
  )
}

export default Home
